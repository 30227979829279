// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import React from 'react';
//import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createRoot } from 'react-dom/client';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCboDDfzhulrtwZcIciXy6mEKCy5DnlQfs",
  authDomain: "insa-thiele-eich.firebaseapp.com",
  projectId: "insa-thiele-eich",
  storageBucket: "insa-thiele-eich.appspot.com",
  messagingSenderId: "902816779189",
  appId: "1:902816779189:web:0d8aec4736ea45a38de7fc"
};

const resizeObserver = new ResizeObserver(entries => {
  const [entry] = entries;
  resizeObserver.unobserve(entry.target);
  // Next frame has not been painted when ResizeObserver calls this callback
  requestAnimationFrame(() => {
    // Double-check the element is still in the DOM
    if (!entry.target.isConnected) {
      return;
    }
    resizeObserver.observe(entry.target);
  });
});

resizeObserver.observe(document.body);

// Initialize Firebase
initializeApp(firebaseConfig);

const root = document.getElementById('root');
if (root !== null) {
  const app = createRoot(root);
  app.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}