import { Box, Fade, Button, TextField, Modal, Typography, Card, CardContent } from '@mui/material';
import logo from './IMTEICH Logo.png';
import videoSource from './IMTEICH Intro.mp4';
import videoSourceHochformat from './IMTEICH Intro Hochformat.mp4';
import videoPoster from './IMTEICH Intro.png';
import videoPosterHochformat from './IMTEICH Intro Hochformat.png';
import { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser'; 
import { useMediaQuery } from '@mui/material';

function App() {
  const [showText, setShowText] = useState(false);
  /*const [budget, setBudget] = useState('');*/
  const isMobile = useMediaQuery('(max-width:600px)');
  const [impressumOpen, setImpressumOpen] = useState(false);

  /*const handleBudgetChange = (event) => {
    setBudget(event.target.value);
  };*/

  /*const handleBudgetBlur = (event) => {
    setBudget(parseFloat(event.target.value).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }));
  };*/

  useEffect(() => {
    setTimeout(() => {
      setShowText(true);
    }, 2000);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
  
    var templateParams = {
      from_name: e.target.user_name.value,
      company_name: e.target.company_name.value,
      user_email: e.target.user_email.value,
      phone_number: e.target.phone_number.value,
      inquiry: e.target.inquiry.value,
      //budget: e.target.budget.value,
    };
  
    emailjs.send("service_9jq4rh8","template_yjwg3cb", templateParams, '2pZktBd0n17nVhvCy')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    }, (error) => {
      console.log('FAILED...', error);
    });
    handleClose();
  }

  const textLines = ["Astronautin", "Wissenschaftlerin", "Speakerin", "Moderatorin", "Autorin"];
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImpressumOpen = () => {
    setImpressumOpen(true);
  };

  const handleImpressumClose = () => {
    setImpressumOpen(false);
  };
  
  return (
    <Box sx={{ 
      position: 'relative', 
      width: '100vw', 
      height: '108vh', 
      overflow: 'hidden' 
    }}>
      <div style={{ 
  position: 'relative', 
  overflow: 'hidden', // Versteckt den überflüssigen Inhalt
  width: '100%', 
  height: '100%' ,
  backgroundColor: 'black', // Setzt den Hintergrund auf Schwarz
  zIndex: '-2' // Setzt die Z-Index-Eigenschaft auf -1, um das Video hinter den anderen Elementen zu platzieren
}}>
  <video autoPlay="autoplay" loop="loop" playsInline muted poster={isMobile? videoPosterHochformat : videoPoster} style={{ 
    position: 'absolute',
    width: '100%', // Wenn es ein Mobilgerät ist, erhöhen Sie die Breite um 3cm, sonst setzen Sie die Breite auf 100%
    right: '0', // Wenn es ein Mobilgerät ist, verschieben Sie das Video 3cm nach links, sonst setzen Sie right auf 0
    top: '50%',
    height: '100%',
    objectFit: isMobile ? 'contain' : 'cover', // Wenn es ein Mobilgerät ist, passen Sie das Video an, sonst decken Sie den Bildschirm ab
    transform: 'translate(0, -50%)', // Ändert die Transformation, um das Video korrekt zu positionieren
    zIndex: '-1',
    objectPosition: 'top right'
  }}>
    <source src={isMobile? videoSourceHochformat : videoSource} type="video/mp4" />
  </video>
</div>
      <Box sx={{ 
        position: 'absolute', 
        top: 0, 
        left: '2%', 
        padding: 2 
      }}>
        <img src={logo} alt="Insa Thiele-Eich" style={{ width: 150, height: 'auto' }} />
      </Box>
      <Box sx={{ 
        position: 'absolute', 
        top: '18%', 
        left: '2.5%', 
        //padding: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Gemeinsamer halbtransparenter Hintergrund
        borderRadius: '10px',
        padding: '10px'
      }}>
        {textLines.map((line, index) => (
          <Fade in={showText} timeout={(index + 1) * 1000} key={index}>
            <p style={{ 
              color: 'white', 
              fontSize: isMobile? '20px' : '40px', // Kleinere Schriftgröße für bessere Passform
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
              margin: isMobile? '' : '10pt', // Kein zusätzlicher Abstand zwischen den Zeilen
              padding: '10px 0' // Weniger Padding für bessere Passform
            }}>
              {line}
            </p>
          </Fade>
        ))}
      </Box>
      <Box sx={{ 
        position: 'absolute', 
        top: 0, 
        right: 0, 
        padding: 2 
      }}>
        {/*<Navbar />*/}
      </Box>
      <Box sx={{ 
        position: 'absolute', 
        bottom: isMobile? '' : '15%', 
        top: isMobile? '75%' : '',
        left: '50%', 
        transform: 'translateX(-50%)' 
      }}>

        <Button 
          variant="contained" 
          onClick={handleClickOpen}
          style={{ 
            backgroundColor: isMobile? 'white' : '#f18916',
            color: isMobile? '#f18916' : 'white',}}
        >
          Kontakt
        </Button>

        <Modal
          open={open}
          onClose={handleClose}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ 
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            maxWidth: '90%',
            width: '100%', // Full width
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px'
          }}>
            <form onSubmit={sendEmail} style={{ 
              width: '100%', 
              display: 'flex',
              flexDirection: 'column',
              gap: '20px'
            }}>
              <TextField label="Name" name="user_name" variant="outlined" required fullWidth />
              <TextField label="Unternehmensname" name="company_name" variant="outlined" fullWidth />
              <TextField label="Email" name="user_email" variant="outlined" required fullWidth />
              <TextField label="Telefonnummer" name="phone_number" variant="outlined" required fullWidth />
              <TextField
                label="Anfrage"
                name="inquiry"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                required
                inputProps={{ style: { whiteSpace: 'pre-wrap' }}}
                style={{ overflowY: 'auto' }}
              />
            {
              /* 
              <TextField 
                label="Budget" 
                name="budget" 
                variant="outlined" 
                value={budget}
                onChange={handleBudgetChange}
                onBlur={handleBudgetBlur}
                fullWidth
              />
              */
            }
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Senden
              </Button>
            </form>
          </Box>
        </Modal>
      </Box>
      {/* Footer with Impressum button */}
      <Box sx={{ 
        position: 'absolute', 
        top: '100vh', // Place it just below the visible viewport
        width: '100%', 
        backgroundColor: 'rgba(0, 0, 0, 0.7)', 
        color: 'white', 
        textAlign: 'center', 
        padding: '10px 0' 
      }}>
        <Button onClick={handleImpressumOpen} style={{ color: 'white' }}>Impressum</Button>
      </Box>

      <Modal
        open={impressumOpen}
        onClose={handleImpressumClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card sx={{ 
    maxWidth: 600, 
    margin: 'auto',
    padding: 2,
    maxHeight: '80vh', // Ensures the modal does not exceed 80% of the viewport height
    overflowY: 'auto' // Enables scrolling within the modal if the content is too long
  }}>
    <CardContent>
      <Typography variant="h4" component="h2" gutterBottom>
        Impressum
      </Typography>
      <Typography variant="h6" component="h3" gutterBottom>
        Angaben gemäß § 5 TMG
      </Typography>
      <Typography variant="body2" component="p" gutterBottom>
        Schönhauser Promotion GmbH<br />
        Cäsarstr. 58<br />
        50968 Köln
      </Typography>
      <Typography variant="body2" component="p" gutterBottom>
        Handelsregister: 31287<br />
        Registergericht: HRB Köln
      </Typography>
      <Typography variant="body2" component="p" gutterBottom>
        Vertreten durch: Ralf Helle
      </Typography>
      
      <Typography variant="h6" component="h3" gutterBottom>
        Kontakt
      </Typography>
      <Typography variant="body2" component="p" gutterBottom>
        Telefon: +49 (0) 221 348 084 0<br />
        E-Mail: info@schoenhauser-promotion.de
      </Typography>
      
      <Typography variant="h6" component="h3" gutterBottom>
        Umsatzsteuer-ID
      </Typography>
      <Typography variant="body2" component="p" gutterBottom>
        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE 200 081 904
      </Typography>
      
      <Typography variant="h6" component="h3" gutterBottom>
        Redaktionell verantwortlich
      </Typography>
      <Typography variant="body2" component="p" gutterBottom>
        Ralf Helle<br />
        Schönhauser Promotion GmbH<br />
        Cäsarstr. 58<br />
        D-50968 Köln
      </Typography>
      
      <Typography variant="h6" component="h3" gutterBottom>
        EU-Streitschlichtung
      </Typography>
      <Typography variant="body2" component="p" gutterBottom>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>. Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </Typography>
      
      <Typography variant="h6" component="h3" gutterBottom>
        Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
      </Typography>
      <Typography variant="body2" component="p" gutterBottom>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </Typography>
    </CardContent>
  </Card>
</Modal>
    </Box>
  );
}

export default App;
